// TABLES

table {

	
	//* ANCHOR GLOBAL *//

	th, td {
		@include typography(body-normal, true);
		margin: 0px;
	}

	.small-text,
	.xsmall-text {
		margin: 0px;
	}



	//* ANCHOR HEADING *//

	th {
		font-weight: $fw-bold;
		background-color: $table-head-background;
		color: white;
	}



	//* ANCHOR CELLS *//

	td {
		color: var(--tx-primary-color);
	}


	//* ANCHOR BORDER *//

	tbody tr {
		border-top: 1px solid var(--bg-0-color);
	}
}
// VARIABLES
// All global sass variables are listed here.


//* ANCHOR FONT FAMILIES *//

$font-family-sans-serif: 	'Source Sans Pro', sans-serif;
$font-family-serif: 		'Source Serif Pro', serif;



//* ANCHOR FONT WEIGHTS *//

$fw-light: 		300;
$fw-regular: 	400;
$fw-bold: 		700;



//* ANCHOR TRANSITIONS *//

$global-transition-duration: 250ms;
$global-transition-ease: ease-out;

$global-transition: $global-transition-duration $global-transition-ease;



//* ANCHOR SCROLLBARS *//

$scrollbar-width: 					rem-calc(6);
$scrollbar-thumb-color: 			Primary4(300);
$scrollbar-track-color: 			Secondary1(100);



//* ANCHOR HEADER *//

$header-height: 					fluid-value(72, 56);



//* ANCHOR GLOBAL SPACINGS *//

$page-spacing:						Spacing(XL);
$main-content-spacing:				Spacing(XL);



//* ANCHOR MAX WIDTHS *//

$app-max-width:						1920px;
$column-max-width:					1366px;



//* ANCHOR BORDER WIDTH *//

$br-width: 							fluid-value(3, 5);



//* ANCHOR GLOBAL ACTION VARIABLES *//

$action-padding: 					Spacing(XS) Spacing(S);


// ACTIVE
$action-active-dark: 				Primary2(700);
$action-active-light: 				white;


// PRIMARY
// Default
$action-primary-default-light: 		white;
$action-primary-default-dark: 		Primary3(500);
$action-primary-default-midtone: 	Primary3(50);

// Hover
$action-primary-hover-light: 		white;
$action-primary-hover-dark: 		Primary3(700);
$action-primary-hover-midtone: 		Primary3(100);

// Focus
$action-primary-focus-light: 		white;
$action-primary-focus-dark: 		Primary3(900);


// SECONDARY
// Default
$action-secondary-default-light: 	white;
$action-secondary-default-dark: 	Primary2(500);
$action-secondary-default-midtone: 	Primary2(50);

// Hover
$action-secondary-hover-light: 		white;
$action-secondary-hover-dark: 		Primary2(700);
$action-secondary-hover-midtone: 	Primary2(100);

// Focus
$action-secondary-focus-light: 		white;
$action-secondary-focus-dark: 		Primary2(900);


// INACTIVE
$action-inactive-dark: 				Secondary1(400);
$action-inactive-light: 			Secondary1(50);



//* ANCHOR FORMS *//

$form-element-spacing: 				Spacing(S);
$form-element-spacing-large: 		Spacing(L);
$form-element-br-width: 			fluid-value(2, 3);
$form-element-padding: 				Spacing(XS) Spacing(S);


// DEFAULT
$form-default-br-color: 			Primary4(200);
$form-default-icon-color: 			Primary4(100);
$form-default-bg-color: 			white;
$form-default-fg-color: 			Secondary1(900);
$form-default-link-color: 			Primary3(600);


// FOCUS
$form-focus-br-color: 				Primary2(500);
$form-focus-icon-color: 			Primary2(300);
$form-focus-bg-color: 				$form-default-bg-color;
$form-focus-fg-color: 				$form-default-fg-color;
$form-focus-link-color: 			Primary2(500);


// INACTIVE
$form-inactive-br-color: 			Primary4(100);
$form-inactive-icon-color: 			Primary4(100);
$form-inactive-bg-color: 			$form-default-bg-color;
$form-inactive-fg-color: 			Primary4(400);
$form-inactive-link-color: 			Primary4(100);


// PLACEHOLDER
$form-placeholder-fg-color: 		Primary4(200);



//* ANCHOR TOOLTIPS *//

$tooltip-bg-color: 					Primary3(500);
$tooltip-fg-color: 					white;
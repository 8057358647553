// FOOTER

.footer {
	position: relative;
	padding: fluid-value(48, 120) $page-spacing;
	background-color: var(--bg-0-color);

	@include breakpoint(large up) {
		text-align: center;
	}


	& > div {
		max-width: $column-max-width;
		margin: 0 auto;
	}

	p {
		margin: 0px;
	}

	

	//* ANCHOR FOOTER TOP *//

	.footer-top {
		display: flex;
		flex-direction: column;
		margin-bottom: Spacing(L);
		
		@include breakpoint(large) {
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}


		// DECORATIVE ARROWS
		@include breakpoint(large) {
			.logo,
			.moto {
				display: flex;
				align-items: center;
				@include get-icon(after, $layout-icons, arrow, var(--br-secondary-color), rem-calc(40));

				&:after {
					margin-left: Spacing(S);
					margin-right: Spacing(S);
				}
			}

			.moto {
				margin-right: Spacing(S);
			}
		}
	}



	//* ANCHOR LOGO *//
	// For global logo styles please see components/_logo.scss

	.logo {
		display: flex;
		align-items: center;
		
		a {
			font-size: 0px;
			display: inline-block;
			background-repeat: no-repeat;
			background-size: 100%;
			background-image: url(../img/eurofound-logo.svg);
			width: fluid-value(126, 146);
			height: fluid-value(38, 44);
			box-shadow: 0 0 0 5px var(--extra-border-color);
			transition: $global-transition;
		
			// HOVER
			@include hover {
				--extra-border-color: var(--link-hover-bg-color);
			}
		
			
			// FOCUS
			&:focus-visible {
				outline: none;
				--extra-border-color: var(--link-focus-bg-color);
			}
		}
	}



	//* ANCHOR MOTO *//
	
	p.moto {
		@include typography(ui-normal);
		text-align: left;

		@include breakpoint(medium-a down) {
			margin-top: Spacing(S);
			margin-bottom: Spacing(L);
		}

		@include breakpoint(large up) {
			max-width: rem-calc(230);
		}
	}



	//* ANCHOR DISCLAIMER *//

	.disclaimer {
		font-weight: $fw-regular;
		color: var(--tx-secondary-color);
	}



	//* ANCHOR COPYRIGHT *//

	.copyright {
		margin-top: Spacing(3XS);
		font-weight: $fw-bold;
	}
}
// COLORS
// Color maps and functions are included in this file.
// If a new map is added please copy and rename an already existing function.


//* ANCHOR PRIMARY 1 *//

$primary-1-color-map: (
	900: 		#2c5048,
	800: 		#39695f,
	700: 		#4a887a,
	600: 		#5fae9d,
	500: 		#68bfac,
	400: 		#86ccbd,
	300: 		#9ad4c7,
	200: 		#bae2d9,
	100: 		#d0ebe5,
	50: 		#f0f9f7,
);


// FUNCTION
@function Primary1($color-number: 900 50) {
	@return get-value-if-key-exists($primary-1-color-map, $color-number);
}



//* ANCHOR PRIMARY 2 *//

$primary-2-color-map: (
	900: 		#173731,
	800: 		#1e4840,
	700: 		#265c53,
	600: 		#31766a,
	500: 		#368275,
	400: 		#5e9b91,
	300: 		#78aba3,
	200: 		#a3c6c0,
	100: 		#c1d8d4,
	50: 		#ebf3f1,
	700-trans: 	#265c53e6,
);


// FUNCTION
@function Primary2($color-number: 900 50) {
	@return get-value-if-key-exists($primary-2-color-map, $color-number);
}



//* ANCHOR PRIMARY 3 *//

$primary-3-color-map: (
	900: 		#03354a,
	800: 		#034561,
	700: 		#04597e,
	600: 		#0573a1,
	500: 		#067eb1,
	400: 		#3898c1,
	300: 		#58a9cb,
	200: 		#8cc4db,
	100: 		#b2d7e7,
	50: 		#e6f2f7,
);


// FUNCTION
@function Primary3($color-number: 900 50) {
	@return get-value-if-key-exists($primary-3-color-map, $color-number);
}



//* ANCHOR PRIMARY 4 *//

$primary-4-color-map: (
	900: 		#0b1424,
	800: 		#0e1a2f,
	700: 		#12213c,
	600: 		#182b4d,
	500: 		#1a2f55,
	400: 		#485977,
	300: 		#66748d,
	200: 		#969fb1,
	100: 		#b8bfca,
	50: 		#e8eaee,
	500-trans:	#1a2f55e6
);


// FUNCTION
@function Primary4($color-number: 900 50) {
	@return get-value-if-key-exists($primary-4-color-map, $color-number);
}



//* ANCHOR PRIMARY 5 *//

$primary-5-color-map: (
	900: 		#1d1829,
	800: 		#271f35,
	700: 		#322845,
	600: 		#403358,
	500: 		#463861,
	400: 		#6b6081,
	300: 		#837a95,
	200: 		#aaa3b6,
	100: 		#c6c1ce,
	50: 		#edebef,
);


// FUNCTION
@function Primary5($color-number: 900 50) {
	@return get-value-if-key-exists($primary-5-color-map, $color-number);
}



//* ANCHOR SECONDARY 1 *//

$secondary-1-color-map: (
	900: 		#202020,
	800: 		#2a2a2a,
	700: 		#363636,
	600: 		#454545,
	500: 		#4c4c4c,
	400: 		#707070,
	300: 		#878787,
	200: 		#adadad,
	100: 		#c8c8c8,
	50: 		#ededed,
);


// FUNCTION
@function Secondary1($color-number: 900 50) {
	@return get-value-if-key-exists($secondary-1-color-map, $color-number);
}



//* ANCHOR SECONDARY 2 *//

$secondary-2-color-map: (
	900: 		#616363,
	800: 		#808182,
	700: 		#a5a7a8,
	600: 		#d3d6d7,
	500: 		#e8ebec,
	400: 		#edeff0,
	300: 		#f0f2f2,
	200: 		#f4f6f6,
	100: 		#f8f9f9,
	50: 		#fdfdfd,
);


// FUNCTION
@function Secondary2($color-number: 900 50) {
	@return get-value-if-key-exists($secondary-2-color-map, $color-number);
}



//* ANCHOR SECONDARY 3 *//

$secondary-3-color-map: (
	900: 		#676868,
	800: 		#878888,
	700: 		#afafaf,
	600: 		#e0e1e1,
	500: 		#f6f7f7,
	400: 		#f8f9f9,
	300: 		#f9fafa,
	200: 		#fbfbfb,
	100: 		#fcfdfd,
	50: 		#fefefe,
);


// FUNCTION
@function Secondary3($color-number: 900 50) {
	@return get-value-if-key-exists($secondary-3-color-map, $color-number);
}



//* ANCHOR SUCCESS *//

$success-color-map: (
	700: 		#1b401b,
	600: 		#326b31,
	500: 		#3f843d,
	400: 		#47ba59,
	300: 		#90dd9c,
	200: 		#daf3da,
);


// FUNCTION
@function Success($color-number: 700 200) {
	@return get-value-if-key-exists($success-color-map, $color-number);
}



//* ANCHOR WARNING *//

$warning-color-map: (
	700: 		#3f2200,
	600: 		#784100,
	500: 		#a55900,
	400: 		#db933e,
	300: 		#ecb87a,
	200: 		#fcdebc,
);


// FUNCTION
@function Warning($color-number: 700 200) {
	@return get-value-if-key-exists($warning-color-map, $color-number);
}



//* ANCHOR ERROR *//

$error-color-map: (
	700: 		#4b0000,
	600: 		#b00000,
	500: 		#d00000,
	400: 		#ff3d3d,
	300: 		#ff8080,
	200: 		#fabbbb,
);


// FUNCTION
@function Error($color-number: 700 200) {
	@return get-value-if-key-exists($error-color-map, $color-number);
}



//* ANCHOR BLACK TRANSPARENT *//

$black-trans-color-map: (
	1: 			#000000b3,
	2: 			#00000033,
);


// FUNCTION
@function Black-trans($color-number: 1 2) {
	@return get-value-if-key-exists($black-trans-color-map, $color-number);
}



//* ANCHOR WHITE TRANSPARENT *//

$white-trans-color-map: (
	1: 			rgba(255,255,255,0.9)
);


// FUNCTION
@function White-trans($color-number: 1) {
	@return get-value-if-key-exists($white-trans-color-map, $color-number);
}
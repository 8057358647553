// LANGUAGE SELECTOR

.language-selector {
	display: flex;
	flex-direction: column;
	gap: Spacing(S);


	//* ANCHOR TITLE *//

	.title {
		margin: 0px;
		@include typography(H6, true);
	}

	// INLINE TITLE
	&.inline-title {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
	}



	//* ANCHOR LIST *//

	ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: Spacing(3XS);


		// LINKS
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: Spacing(M);
			height: Spacing(M);
			@include typography(ui-small);
			font-family: $font-family-sans-serif;
			font-weight: $fw-regular;
			text-transform: uppercase;
			color: var(--link-default-fg-color);
			background-color: var(--bg-100-color);

			&.active {
				color: var(--active-fg-color);
				background-color: var(--active-bg-color);
			}

			@include hover {
				color: var(--link-hover-fg-color);
				background-color: var(--link-hover-bg-color);
			}

			&:focus-visible {
				color: var(--link-focus-fg-color);
				background-color: var(--link-focus-bg-color);
			}
		}
	}
}
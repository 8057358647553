// PAGINATION

.pagination-wrapper {
	margin-top: Spacing(L);
	margin-bottom: Spacing(L);

	&:last-child {
		margin-bottom: 0px;
	}
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;



	//* ANCHOR GLOBAL RULES *//

	li a.button {
		--action-br-width: 2px;
	}



	//* ANCHOR ARROWS *//

	li[class*="pagination-"] {
		display: block;

		a {
			padding: Spacing(2XS);
		}
	}




	//* ANCHOR CURRENT *//

	li.current {
		@include typography(ui-normal);
		padding: Spacing(XS) Spacing(S);
	}



	//* ANCHOR ELLIPSIS *//

	li.ellipsis:after {
		padding: 0px;
		font-size: rem-calc(32);
		line-height: 0;
	}
}
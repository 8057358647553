// COLOR THEMES
// CSS variables for all color properties inside each surface.


//* ANCHOR DEFAULT COLOR VALUES *//
// Used in root and .surface-a

%default-color-values {
		
	// BACKGROUND
	--bg-0-color: 						white;
	--bg-100-color: 					#{Primary4(50)};
	--bg-200-color: 					#{Primary4(100)};
	--bg-0-trans-color: 				#{White-trans(1)};


	// HEADINGS
	--headings-primary-color: 			#{Primary4(500)};
	--headings-secondary-color: 		#{Primary2(600)};

	// ACTIVE
	--active-bg-color: 					#{Primary2(600)};
	--active-fg-color: 					white;

	// LIST DECORATION
	--list-decor-color: 				#{Primary4(500)};

	// BORDERS
	--br-primary-color: 				#{Primary4(500)};
	--br-secondary-color:		 		#{Secondary2(600)};

	// TEXT
	--tx-primary-color: 				#{Secondary1(900)};
	--tx-secondary-color: 				#{Secondary1(500)};
	--tx-tertiary-color: 				#{Secondary1(400)};

	// LINKS
	// Default
	--link-default-fg-color:			#{Primary3(600)};

	// Hover
	--link-hover-fg-color: 				white;
	--link-hover-bg-color: 				#{Primary3(700)};

	// Focus
	--link-focus-fg-color: 				white;
	--link-focus-bg-color: 				#{Primary3(900)};

	// TABLES
	--th-bg-color: 						#{Primary4(500)};
}

:root {
	@extend %default-color-values;
}

	

//* ANCHOR SURFACE A *//

.surface-a {
	@extend %default-color-values;
}



//* ANCHOR SURFACE B *//

.surface-b {

	// BACKGROUND
	--bg-0-color: 						#{Secondary2(200)};
	--bg-100-color: 					#{Secondary2(500)};
	--bg-200-color: 					#{Secondary2(600)};
	--bg-0-trans-color: 				#{White-trans(1)};

	// HEADINGS
	--headings-primary-color: 			#{Primary4(500)};
	--headings-secondary-color: 		#{Primary2(600)};

	// ACTIVE
	--active-bg-color: 					#{Primary2(600)};
	--active-fg-color: 					white;

	// LIST DECORATION
	--list-decor-color: 				#{Primary4(900)};

	// BORDERS
	--br-primary-color: 				#{Primary4(500)};
	--br-secondary-color:		 		#{Secondary2(600)};

	// TEXT
	--tx-primary-color: 				#{Secondary1(900)};
	--tx-secondary-color: 				#{Secondary1(500)};
	--tx-tertiary-color: 				#{Secondary1(400)};

	// LINKS
	// Default
	--link-default-fg-color:			#{Primary3(700)};

	// Hover
	--link-hover-fg-color: 				white;
	--link-hover-bg-color: 				#{Primary3(800)};

	// Focus
	--link-focus-fg-color: 				white;
	--link-focus-bg-color: 				#{Primary3(900)};

	// TABLES
	--th-bg-color: 						#{Primary4(500)};
}



//* ANCHOR SURFACE C *//

.surface-c {

	// BACKGROUND
	--bg-0-color: 						#{Primary4(500)};
	--bg-100-color: 					#{Primary4(700)};
	--bg-200-color: 					#{Primary4(800)};
	--bg-0-trans-color: 				#{Primary4(500-trans)};

	// HEADINGS
	--headings-primary-color: 			white;
	--headings-secondary-color: 		#{Primary1(600)};

	// ACTIVE
	--active-bg-color: 					#{Primary2(600)};
	--active-fg-color: 					white;

	// LIST DECORATION
	--list-decor-color: 				#{Primary2(400)};

	// BORDERS
	--br-primary-color: 				#{Primary4(300)};
	--br-secondary-color:		 		#{Primary2(700)};

	// TEXT
	--tx-primary-color: 				white;
	--tx-secondary-color: 				#{Primary4(100)};
	--tx-tertiary-color: 				#{Primary4(200)};

	// LINKS
	// Default
	--link-default-fg-color:			#{Primary1(400)};

	// Hover
	--link-hover-fg-color: 				white;
	--link-hover-bg-color: 				#{Primary4(800)};

	// Focus
	--link-focus-fg-color: 				#{Primary3(900)};
	--link-focus-bg-color: 				white;

	// TABLES
	--th-bg-color: 						#{Primary4(800)};
}



//* ANCHOR SURFACE D *//

.surface-d {

	// BACKGROUND
	--bg-0-color: 						#{Primary2(700)};
	--bg-100-color: 					#{Primary2(800)};
	--bg-200-color: 					#{Primary2(900)};
	--bg-0-trans-color: 				#{Primary2(700-trans)};

	// HEADINGS
	--headings-primary-color: 			white;
	--headings-secondary-color: 		#{Primary1(300)};

	// ACTIVE
	--active-bg-color: 					#{Primary4(600)};
	--active-fg-color: 					#{Primary1(300)};

	// LIST DECORATION
	--list-decor-color: 				#{Primary1(400)};

	// BORDERS
	--br-primary-color: 				#{Primary4(600)};
	--br-secondary-color:		 		#{Primary2(600)};

	// TEXT
	--tx-primary-color: 				white;
	--tx-secondary-color: 				#{Primary1(200)};
	--tx-tertiary-color: 				#{Primary1(300)};

	// LINKS
	// Default
	--link-default-fg-color:			#{Primary1(50)};

	// Hover
	--link-hover-fg-color: 				white;
	--link-hover-bg-color: 				#{Primary2(900)};

	// Focus
	--link-focus-fg-color: 				#{Primary1(100)};
	--link-focus-bg-color: 				#{Primary2(900)};

	// TABLES
	--th-bg-color: 						#{Primary2(900)};
}
// TABS

.tabs-wrapper {


	//* ANCHOR HORIZONTAL TABS *//

	&:not(.vertical) {
		ul.tabs {
			display: flex;
			flex-direction: column;

			@include breakpoint(medium) {
				flex-direction: row;
			}
		}
	}



	//* ANCHOR VERTICAL TABS *//

	&.vertical {
		@include breakpoint(medium) {
			ul.tabs {
				display: flex;
			}
		}

		@include breakpoint(large) {
			display: grid;
			grid-template-columns: auto 1fr;

			ul.tabs {
				display: block;
				max-width: 300px;
			}
		}
	}



	//* ANCHOR TABS LIST *//

	ul {
		background-color: var(--bg-200-color);
	}



	//* ANCHOR TAB ITEM *//

	a.button {
		padding: $action-padding;
		@include typography(ui-normal);
		background-color: var(--tab-bg-color, var(--action-bg-color));
		border: Spacing(3XS) solid var(--tab-br-color, var(--action-bg-color));
		color: var(--tab-fg-color, var(--action-fg-color));

		@include hover {
			--tab-br-color:  var(--action-bg-hover-color);
			--tab-bg-color:  var(--action-bg-hover-color);
			--tab-fg-color:  var(--action-fg-hover-color);
		}

		&:focus-visible {
			--tab-br-color:  var(--action-bg-focus-color);
			--tab-bg-color:  var(--action-bg-focus-color);
			--tab-fg-color:  var(--action-fg-focus-color);
		}
	}

	// ACTIVE
	li.is-active a.button {
		--tab-bg-color: var(--bg-100-color);
		--tab-fg-color: var(--tx-primary-color);
		--tab-br-color: var(--bg-100-color);

		&:focus-visible {
			--tab-br-color:  var(--action-bg-focus-color);
			--tab-bg-color:  var(--action-bg-focus-color);
			--tab-fg-color:  var(--action-fg-focus-color);
		}
	}



	//* ANCHOR PANELS *//

	.tabs-panel {

		& > *:last-child {
			margin-bottom: 0px;
		}

	}
}
// BUTTON GROUP

.button-group {


	//* ANCHOR VERTICAL *//

	&.vertical {
		flex-direction: column;
		align-items: flex-start;
	}
}
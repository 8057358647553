// CARD

.card {


	//* ANCHOR TITLE *//

	.card-divider {
		padding-top: Spacing(S);
		padding-bottom: Spacing(S);
		color: var(--bg-0-color);

		& > * {
			@include typography(H5, true);
			margin: 0px;
			color: inherit;
		}
	}



	//* ANCHOR WITH ICON *//

	&[class*="icon-"] .card-divider {
		display: flex;
		align-items: center;
		gap: Spacing(XS);
		@include get-icon(before, $common-icons, none, inherit, fluid-value(15, 20));
	}

	@each $class, $icon in $common-icons {
		&.icon-#{$class} .card-divider:before {
			content: $icon;
		}
	}



	//* ANCHOR CONTENT *//
	
	.card-section p {
		margin: 0px;
	}



	//* ANCHOR FOOTER *//

	.card-footer {
		padding-top: Spacing(XS);
		padding-bottom: Spacing(S);
		border-top: 1px solid var(--br-secondary-color);
		margin-top: Spacing(M); 

		p {
			@include typography(body-small, true);
			font-weight: $fw-bold;
		}
	}
}
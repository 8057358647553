// BREADCRUMB

ul.breadcrumbs {


	//* ANCHOR ITEMS *//

	li {
		@include typography(ui-normal);
		font-family: $font-family-sans-serif;
		font-weight: $fw-regular;
	}



	//* ANCHOR LINKS *//

	a:hover {
		text-decoration: none;
	}
}
// SEARCH FORM

.search-form {


	//* ANCHOR ACTIONS *//

	.actions {
		justify-content: flex-end;
	}
}
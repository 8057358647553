// RICH TEXT
// The .rich-text class is added on wrappers of CMable content.
// It adds underlines in content links.


.rich-text {


	//* ANCHOR LINKS *//

	p,
	li:not(.accordion-item),
	dd,
	p > strong,
	h1, h2, h3, h4, h5,
	td, th {
		& > a {
			border-bottom: 1px solid var(--link-default-fg-color);
			word-wrap: break-word;

			// HOVER
			@include hover {
				background-color: transparent;
				border-color: var(--link-hover-bg-color);
			}

			// Hover animation
			@include bg-color-animation(transparent, var(--final-bg-color, var(--link-hover-bg-color)), btt, var(--final-fg-color, var(--link-hover-fg-color)), hover);


			// FOCUS
			&:focus-visible {
				outline: none;
				background-color: var(--link-focus-bg-color);
				color: var(--link-focus-fg-color);
				border-color: var(--link-focus-bg-color);
			}
		}
	}

	p,
	.bullet-list > li,
	.number-list > li,
	td {
		& > a {
			font-weight: $fw-regular;
		}
	}



	//* ANCHOR HEADINGS *//

	h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
		color: var(--headings-secondary-color);
	}
}
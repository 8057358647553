// FLUID VALUES
// A function based on "UTOPIA" https://utopia.fyi/ that creates fluid responsive values for css properties.

// It is based on a min and a max breakpoint, as well as a min and a max value.

// The min value is used in screens smaller than the min breakpoint, the max value for screens larger than the max breakpoint and the fluid magic happens between the two breakpoints.


//* ANCHOR VARIABLES *//

// BREAKPOINTS
$breakpoint-min: 	360;
$breakpoint-max: 	1920;


// UTILITY VARIABLES
// These variables should not be altered.
$breakpoint-fluid: 
	calc(
		(var(--fluid-screen) - #{$breakpoint-min} / 16 * 1rem) /
		(#{$breakpoint-max} - #{$breakpoint-min})
	);
 
:root {
	--fluid-screen: #{$breakpoint-min * 1px};
	
	@include breakpoint($breakpoint-min up) {
		--fluid-screen: 100vw;
	}

	@include breakpoint($breakpoint-max up) {
		--fluid-screen: #{$breakpoint-max * 1px};
	}
}



//* ANCHOR FUNCTION *//

// USE: 			Creates fluid responsive values.
// AUTHOR: 			EWORX
// IMPORTANT NOTE: 	Don't use units in $min and $max.
// EXAMPLE: 		font-size: fluid-value(16, 20);

@function fluid-value($min, $max) {
	@return calc(
		((#{$min} / 16) * 1rem) + (#{$max} - #{$min}) * #{$breakpoint-fluid}
	);
}
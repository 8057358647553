// WRAPPERS
// Content wrappers that work along with surface classes.


//* ANCHOR SECTION (FULL WIDTH) *//

.section {
	padding-top: Spacing(XL);
	padding-bottom: Spacing(XL);
	@include full-screen-background(var(--bg-0-color));

	@include breakpoint(large) {
		padding: Spacing(3XL) 0px;
	}

	
	// SPACINGS
	&:last-child {
		margin-bottom: calc(-1 * #{$main-content-spacing});
	}

	* + &,
	& + * {
		margin-top: fluid-value(48, 120);
	}

	& + .section {
		margin-top: 0px;
	}
}



//* ANCHOR PANEL *//

.panel {
	padding-top: Spacing(XL);
	padding-bottom: Spacing(XL);
	@include full-screen-background(var(--bg-0-color));
	
	@include breakpoint(large) {
		padding-left: Spacing(XL);
		padding-right: Spacing(XL);
		background: var(--bg-0-color);

		&:before {
			content: none;
		}
	}


	// SPACINGS
	&:last-child {
		margin-bottom: calc(-1 * #{$main-content-spacing});

		@include breakpoint(large) {
			margin-bottom: 0px;
		}
	}

	.content > &:first-child {
		margin-top: calc(-1 * #{$main-content-spacing});

		@include breakpoint(large) {
			margin-top: 0px;
		}
	}
}
// SITE HEADER

.site-header {
	padding-bottom: Spacing(XL);

	@include breakpoint(large) {
		padding-bottom: 0px;
	}

	// Background
	@include full-screen-background(var(--bg-0-color));

	// Border
	@include full-screen-background(var(--br-primary-color), after);

	&:after {
		top: auto;
		bottom: 0px;
		height: $br-width;
	}
	


	//* ANCHOR LAYOUT *//

	& > div {
		@include breakpoint(large) {
			display: grid;
			grid-template-columns: 65.4fr 34.6fr;
			column-gap: Spacing(L);
		}
	}



	//* ANCHOR CONTENT *//

	.content {
		padding-top: Spacing(XL);
		
		@include breakpoint(large) {
			padding-bottom: Spacing(XL);
		}

		.title {
			@include typography(H1, true);
			margin: 0px;
			color: var(--headings-primary-color);
		}
	
		.subtitle {
			color: var(--headings-primary-color);
		}

		p {
			margin: 0px;
		}

		& > * + p {
			margin-top: Spacing(2XS);
		} 
	}



	//* ANCHOR IMAGE *//

	.image {
		text-align: center;
		margin-top: Spacing(L);

		@include breakpoint(large) {
			margin-top: 0px;
		}

		img {
			height: 100%;
			object-fit: contain;
		}
	}



	//* ANCHOR COLLAPSED *//

	@include breakpoint(medium-a down) {
		&.short {
			padding: 0 $page-spacing;

			& > div {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: Spacing(L);
				height: fluid-value(55, 72);
			}

			.content {
				padding: 0px;
			}

			h1 {
				@include typography(H5, true);
			}

			p {
				display: none;
			}

			.image {
				height: 100%;
				max-height: 100%;
				padding: $br-width 0;
				margin: 0px;
			}
		}
	}
}
// BUTTONS
// Can be used either on <button>, <a> or form <input> elements.


//* ANCHOR USER AGENT OVERRIDES *//

input.button {
	border: 0px;
}



.button {

	//* ANCHOR BUTTON STRUCTURE *//

	display: inline-block;
	outline: none;
	padding: var(--action-padding, $action-padding);
	@include typography(ui-normal);
	color: var(--action-fg-color);
	background-color: var(--action-bg-color);
	box-shadow: inset 0px 0px 0px var(--action-br-width) var(--action-br-color);
	cursor: pointer;
	transition: background-color $global-transition,
				color $global-transition,
				box-shadow $global-transition,
				border $global-transition; // Tabs


	// HOVER
	@include hover {
		background-color: var(--action-bg-hover-color);
		color: var(--action-fg-hover-color);
		box-shadow: inset 0px 0px 0px var(--action-br-width) var(--action-br-hover-color);
	}


	// FOCUS
	&:focus-visible {
		background-color: var(--action-bg-focus-color);
		color: var(--action-fg-focus-color);
		box-shadow: inset 0px 0px 0px var(--action-br-width) var(--action-br-focus-color);
	}


	// DISABLED
	&[disabled] {
		background-color: var(--action-bg-disabled-color);
		color: var(--action-fg-disabled-color);
		box-shadow: inset 0px 0px 0px var(--action-br-width) var(--action-br-disabled-color);
		cursor: not-allowed;

		@include hover {
			--action-fg-hover-color: 		var(--action-fg-disabled-color);
			--action-bg-hover-color: 		var(--action-bg-disabled-color);
			--action-br-hover-color: 		var(--action-br-disabled-color);
		}
	}



	//* ANCHOR PRIMARY *//

	--action-fg-color: 						#{$action-primary-default-light};
	--action-bg-color: 						#{$action-primary-default-dark};
	--action-br-color:  					transparent;
	--action-br-width:  					0;

	// Hover
	--action-fg-hover-color: 				#{$action-primary-hover-light};
	--action-bg-hover-color: 				#{$action-primary-hover-dark};

	// Focus
	--action-fg-focus-color: 				#{$action-primary-focus-light};
	--action-bg-focus-color: 				#{$action-primary-focus-dark};

	// Disabled
	--action-fg-disabled-color: 			#{$action-inactive-dark};
	--action-bg-disabled-color: 			#{$action-inactive-light};



	//* ANCHOR SECONDARY *//

	&.secondary {
		--action-fg-color: 					#{$action-secondary-default-light};
		--action-bg-color: 					#{$action-secondary-default-dark};

		// Hover
		--action-fg-hover-color: 			#{$action-secondary-hover-light};
		--action-bg-hover-color: 			#{$action-secondary-hover-dark};

		// Focus
		--action-fg-focus-color: 			#{$action-secondary-focus-light};
		--action-bg-focus-color: 			#{$action-secondary-focus-dark};

		// Disabled
		--action-fg-disabled-color: 		#{$action-inactive-dark};
		--action-bg-disabled-color: 		#{$action-inactive-light};
	}



	//* ANCHOR OUTLINED *//

	&.outlined {
		--action-bg-color: 					#{$action-primary-default-light};
		--action-fg-color: 					#{$action-primary-default-dark};
		--action-br-color: 					#{$action-primary-default-dark};
		--action-br-width: 					#{fluid-value(2, 3)};

		// Hover
		--action-bg-hover-color: 			#{$action-primary-hover-light};
		--action-fg-hover-color: 			#{$action-primary-hover-dark};
		--action-br-hover-color: 			#{$action-primary-hover-dark};

		// Focus
		--action-bg-focus-color: 			#{$action-primary-focus-light};
		--action-fg-focus-color: 			#{$action-primary-focus-dark};
		--action-br-focus-color: 			#{$action-primary-focus-dark};

		// Disabled
		--action-bg-disabled-color: 		white;
		--action-fg-disabled-color: 		#{$action-inactive-dark};
		--action-br-disabled-color: 		#{$action-inactive-light};


		// SECONDARY
		&.secondary {
			--action-bg-color: 				#{$action-secondary-default-light};
			--action-fg-color: 				#{$action-secondary-default-dark};
			--action-br-color: 				#{$action-secondary-default-dark};

			// Hover
			--action-fg-hover-color: 		#{$action-secondary-hover-dark};
			--action-br-hover-color: 		#{$action-secondary-hover-dark};

			// Focus
			--action-fg-focus-color: 		#{$action-secondary-focus-dark};
			--action-br-focus-color: 		#{$action-secondary-focus-dark};
		}
	}



	//* ANCHOR HOLLOW *//

	&.hollow {
		--action-bg-color: 					#{$action-primary-default-light};
		--action-fg-color: 					#{$action-primary-default-dark};

		// Hover
		--action-bg-hover-color: 			#{$action-primary-hover-light};
		--action-fg-hover-color: 			#{$action-primary-hover-dark};

		// Focus
		--action-bg-focus-color: 			#{$action-primary-focus-light};
		--action-fg-focus-color: 			#{$action-primary-focus-dark};

		// Disabled
		--action-bg-disabled-color: 		white;
		--action-fg-disabled-color: 		#{$action-inactive-dark};


		// SECONDARY
		&.secondary {
			--action-fg-color: 				#{$action-secondary-default-dark};

			// Hover
			--action-fg-hover-color: 		#{$action-secondary-hover-dark};

			// Focus
			--action-fg-focus-color: 		#{$action-secondary-focus-dark};
		}
	}
}



//* ANCHOR SMALL BUTTON AND PLACEHOLDER *//

.button.small,
%small-button {
	@include typography(ui-small);
	--action-padding: #{Spacing(2XS) Spacing(XS)};
}



//* ANCHOR WITH ICONS *//

.button {

	// VARIABLES
	--action-icon-size: 						#{Spacing(S)};
	--action-icon-text-spacing: 				#{Spacing(2XS)};

	&.small {
		--action-icon-size: 					#{Spacing(XS)};
		--action-icon-text-spacing: 			#{Spacing(3XS)};
	}


	// BOTH CASES
	&.icon-left,
	&.icon-right {
		display: inline-grid;
		column-gap: var(--action-icon-text-spacing);
		align-items: center;
	}


	// LEFT
	&.icon-left {
		grid-template-columns: var(--action-icon-size) 1fr;
		text-align: right;
	}


	// RIGHT
	&.icon-right {
		grid-template-columns: 1fr var(--action-icon-size);
		grid-template-rows: 1fr;
		text-align: left;

		&:before {
			grid-column: 2;
			grid-row: 1
		}
	}


	// ICON CLASSES
	&[class*="icon-"] {
		@include get-icon(before, $common-icons, none, inherit, inherit, inherit);
	}

	@each $class, $icon in $common-icons {
		&.icon-#{$class}:before {
			content: $icon;
		}
	}


	// ICON ONLY
	&.icon-only {
		font-size: 0px;
		line-height: 1;
		display: inline-flex;
		align-items: center;
		--action-padding: #{Spacing(XS)};

		&:before {
			font-size: fluid-value(15, 20);
		}

		// Small
		&.small {
			--action-padding: #{Spacing(2XS)};

			&:before {
				font-size: fluid-value(12, 15);
			}
		}
	}
}
// CHARTS


//* ANCHOR BOX *//

.chart-box {
	display: flex;
	flex-direction: column;
	gap: Spacing(2XS);
	padding: Spacing(L);
	background-color: white;
	border: 1px solid Secondary2(600);


	// HORIZONTAL
	&.horizontal {

		@include breakpoint(medium-a) {
			display: grid;
			grid-template-columns: auto 1fr;
			column-gap: Spacing(S);
			align-items: center;

			& > .label {
				max-width: 300px;
			}

			.progress {
				margin-bottom: 0px;
			}
		}
	}



	//* ANCHOR LABEL *//

	.label {
		@include typography(ui-small);
		margin: 0px; 
		font-family: $font-family-sans-serif;
		font-weight: $fw-regular;

		a {
			display: inline-flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 0px;
			@include get-icon(after, $common-icons, arrow-right, inherit, Spacing(XS));

			&:after {
				margin-left: Spacing(S);
			}
		}
	}



	//* ANCHOR PROGRESS BAR *//

	.progress {
		margin-bottom: Spacing(3XS); 

		.progress-meter {
			display: flex;
			align-items: center;
		}

		.progress-meter-text {
			top: auto;
			left: 0px;
			@include typography(ui-normal);
			font-family: $font-family-sans-serif;
			font-weight: $fw-regular;
			margin-left: Spacing(2XS);
			transform: none;
		}
	}
}
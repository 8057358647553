// HEADER

.header {
	position: fixed;
	bottom: 0px;
	width: 100%;
	height: $header-height;
	background-color: var(--bg-100-color);
	z-index: 100;
	
	@include breakpoint(large) {
		position: static;
		background-color: var(--bg-0-color);
	}

	& > div {
		width: 100%;
		height: 100%;
		max-width: $app-max-width;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include breakpoint(large) {
			align-items: stretch;
		}
	}



	//* ANCHOR LOGO *//
	// For more logo styles see components/_logo.scss

	.logo {
		display: flex;
		align-items: center;
		margin-left: Spacing(S);


		// DECORATIVE ARROW
		@include breakpoint(large) {
			margin-left: 0px;
			@include get-icon(after, $layout-icons, arrow, Primary2(400), rem-calc(40));

			&:after {
				margin-left: Spacing(XS);
				margin-right: Spacing(XS);
			}
		}


		// LINK
		a {
			display: flex;
			align-items: stretch;
			@include typography(ui-small);
			transition: $global-transition;

			@include breakpoint(large) {
				padding: Spacing(2XS) Spacing(S);
			}

			&:before {
				content: " ";
				background-repeat: no-repeat;
				background-size: 100%;
				background-image: url(../img/eurofound-logo.svg);
				width: fluid-value(86, 120);
				height: fluid-value(26, 36);
			}

			span {
				display: flex;
				align-items: center;
				padding-left: Spacing(S);
				margin-left: Spacing(S);
				border-left: 1px solid var(--br-primary-color);
			}

			&:focus-visible:before {
				background-color: var(--link-focus-fg-color);
			}
		}
	}



	//* ANCHOR MENU TOGGLER *//

	.title-bar {
		display: flex;
		align-items: center;
		height: 100%;


		// MENU ICON
		.menu-icon {
			font-size: 0px;
			transition: $global-transition;

			&:before,
			&:after {
				transition: inherit;
			}
		}
	}



	//* ANCHOR MENU *//

	.top-bar {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: calc(100% - #{$header-height});
		background-color: var(--bg-0-color);
		overflow-x: hidden;
		overflow-y: auto;
		display: block;

		// DESKTOP
		@include breakpoint(large) {
			position: static;
			width: auto;
			height: auto;
			overflow: visible;
			background-color: transparent;
			display: flex;
			flex-grow: 1;
		}
	}


	// ACTIVE
	.menu li.active > a {
		color: var(--active-fg-color);
		background-color: var(--active-bg-color);
	}
	

	// DESKTOP
	@include breakpoint(large) {
		.main-navigation {
			height: 100%;

			ul.accordion-menu {
				height: 100%;

				& > li {
					border-top: 0px;
				}
			}

			li.is-accordion-submenu-parent > a {
				display: none;
			}

			ul.menu.large-horizontal.nested {
				display: flex !important;
				flex-wrap: nowrap;
				height: 100%;

				& > li {
					width: auto;

					&.active > a {
						color: var(--active-fg-color);
						background-color: var(--active-bg-color);
					}
				}

				& > li > a {
					height: 100%;
					display: flex;
					align-items: center;
					padding: Spacing(S) Spacing(M);
					@include typography(ui-small);
				}
			}
		}
	}
}
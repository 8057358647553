// RELATED CONTENT

.related-content {
	padding: Spacing(L) 0;


	//* ANCHOR TITLE *//

	.title {
		padding-left: Spacing(S);
		padding-right: Spacing(S);
		@include typography(H5, true);
		margin-top: 0px;
		margin-bottom: Spacing(S);
	}



	//* ANCHOR LINKS *//

	a {
		display: flex;
		align-items: center;
		padding: Spacing(2XS) Spacing(S);
	}
}
// CAROUSEL
// SLICK CAROUSEL IS USED

.carousel {
	max-width: $column-max-width;


	
	//* ANCHOR ARROWS *//

	.slick-arrow {
		position: absolute;
		top: 0px;
		z-index: 10;
		padding: Spacing(XS);
		background-color: var(--bg-0-trans-color);
		color: var(--link-default-fg-color);
		@include get-icon(before, $common-icons, arrow-left, inherit, fluid-value(15, 20), inherit, true);
		cursor: pointer;
		transition: $global-transition;

		@include hover {
			background-color: var(--link-hover-bg-color);
			color: var(--link-hover-fg-color);
		}

		&:focus-visible {
			outline: none;
			background-color: var(--link-focus-bg-color);
			color: var(--link-focus-fg-color);
		}


		&.slick-next {
			right: 0px;

			&:before {
				content: map-get($common-icons, arrow-right);
			}
		}
	}



	//* ANCHOR DOTS *//

	.slick-dots {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: Spacing(S);
		margin-top: Spacing(S);

		button {
			width: Spacing(XS);
			height: Spacing(XS);
			border-radius: 50%;
			background-color: var(--link-default-fg-color);
			outline: 0px;
			font-size: 0px;
			cursor: pointer;
			transition: $global-transition;

			@include hover {
				background-color: var(--link-hover-bg-color);
			}

			&:focus-visible {
				background-color: var(--link-focus-bg-color);
			}
		}

		.slick-active button {
			background-color: transparent;
			box-shadow: 0 0 0 5px var(--active-bg-color);
			cursor: default;
		}
	}


	//* ANCHOR ITEM HEIGHT *//

	article.teaser .content {
		min-height: fluid-value(327, 509);
	}

	// PADDINGS
	&.paddings article.teaser .content {
		min-height: fluid-value(280, 400);
	}



	//* ANCHOR MAKE THE ENTIRE TEASER CLICKABLE *//

	article {
		transition: $global-transition;

		@include hover {
			opacity: 0.85;
		}
	
		.title a {
			background-image: none;

			&:before {
				content: " ";
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				transition: $global-transition;
			}

			&:focus-visible:before {
				box-shadow: inset 0 0 0 $br-width var(--link-focus-bg-color);
			}
		}
	}



	//* ANCHOR WITH PADDINGS *//

	&.paddings {
		display: grid;
		grid-template-columns: min-content 1fr min-content;
		column-gap: Spacing(L);

		.slick-dots {
			grid-column: 1 / span 3;
		}

		.slick-arrow {
			align-self: center;
			position: static;
			background-color: var(--bg-100-color);
		}

		@include breakpoint(medium-a) {
			.slick-slide {
				margin: 0 Spacing(2XS);
			}
		}
	}
}
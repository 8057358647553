// TOOLTIPS


//* ANCHOR TOOLTIP TRIGGER *//

.has-tip {
	color: var(--link-default-fg-color);
	@include get-icon(after, $common-icons, info, inherit, Spacing(S), inherit);

	&:after {
		position: relative;
		top: 3px;
	}

	@include hover {
		color: var(--link-hover-bg-color);
	}

	&:focus-visible {
		color: var(--link-focus-bg-color);
	}
}



//* ANCHOR TOOLTIP *//

.tooltip {
	@include typography(body-small, true);
	font-weight: $fw-regular;
}
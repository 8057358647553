// FORMS

.form {


	//* ANCHOR FIELD WRAPPERS *//

	.field {
		display: flex;
		flex-direction: column;
		gap: $form-element-spacing;


		// INLINE LABELS
		&.inline-label {
			@include breakpoint(medium) {
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
			}
		}


		// MESSAGES
		.form-message {
			margin-top: calc(-1 * #{Spacing(XS)});
		}
	}



	//* ANCHOR GLOBAL INPUT STYLES *//

	label:not(.slider-label) input,
	select,
	textarea,
	.select2 .select2-selection--multiple {
		padding: $form-element-padding;
		margin-bottom: 0px;
		@include typography(ui-normal);
		font-weight: $fw-regular;
		font-family: $font-family-sans-serif;
		box-shadow: inset 0 0 0 $form-element-br-width  var(--form-element-br-color, $form-default-br-color);
		border: 0px;
		outline: 0px;
		transition: $global-transition;
		background-color: var(--form-element-bg-color, $form-default-bg-color);
		color: var(--form-element-fg-color, $form-default-fg-color);


		// FOCUS
		&:focus-visible {
			box-shadow: inset 0 0 0 $form-element-br-width $form-focus-br-color; // css variable in not working because of foundation's settings 
		}


		// DISABLED
		&:disabled {
			--form-element-br-color: #{$form-inactive-br-color};
			--form-element-fg-color: #{$form-inactive-fg-color};
		}
	}

	// Fixes input height
	input {
		max-height: fluid-value(40, 50);
	}



	//* ANCHOR SELECTS *//

	.field-type-select {
		cursor: pointer;

		.input {
			position: relative;
			display: flex;
			align-items: center;
			@include get-icon(after, $common-icons, arrow-down, var(--form-element-link-color, $form-default-link-color), Spacing(S));

			&:after {
				position: absolute;
				right: Spacing(S);
				pointer-events: none;
				transition: $global-transition;
			}
		}

		&.disabled {
			--form-element-link-color: #{$form-inactive-link-color};
		}

		select {
			appearance: none;
			padding-right: fluid-value(40, 50);
			cursor: pointer;

			// Disabled
			&:disabled {
				cursor: not-allowed;
			}

			option {
				color: $action-primary-default-dark;

				// Unfortunately not working on FF
				&:checked {
					background-color: $action-primary-default-dark;
					color: $action-primary-default-light;
				}

				&:disabled {
					color: $action-inactive-light;
					background-color: $action-inactive-dark;
				}
			}
		}

		// FOCUS
		&:focus-within {
			--form-element-link-color: #{$form-focus-link-color};

			.input:after {
				transform: rotate(180deg);
			}
		}
	}



	//* ANCHOR RADIO BUTTONS AND CHECKBOXES *//
	
	fieldset.radio-buttons,
	fieldset.checkboxes {
		display: inline-flex;
		flex-direction: column;
		row-gap: Spacing(M);
		column-gap: Spacing(L);


		// GROUP LABEL
		label {
			position: relative;
			cursor: pointer;
		}


		// INPUT
		label input {
			display: inline-block;
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			margin: auto;
			opacity: 0;
			z-index: -1;
		}


		// TEXT LABEL
		label span {
			display: inline-flex;
			align-items: center;
			transition: $global-transition;
			@include typography(ui-normal);
			font-family: $font-family-sans-serif;
			font-weight: $fw-regular;
			color: var(--tx-primary-color);
		
			// Fake Input
			&:before {
				content: " ";
				box-shadow: 0 0 0 fluid-value(2, 3) var(--radio-checkbox-br-color, $form-default-br-color);
				transition: $global-transition;
			}
		}


		// // CHECKED
		input:checked + span {
			--radio-checked-color: #{$form-default-fg-color};
			--checkbox-opacity: 1;
		}


		// FOCUS
		input:focus-visible + span {
			--radio-checkbox-br-color: #{$form-focus-br-color};
		}


		// HORIZONTAL AN INLINE LABEL
		&.horizontal,
		&.inline-label {
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;

			.label {
				flex-basis: 100%;
			}
		}


		// INLINE LABEL
		&.inline-label .label {
			flex-basis: auto;
			margin-bottom: 0px;
		}


		// DISABLED
		&:disabled {

			label {
				cursor: not-allowed;
				--radio-checkbox-br-color: #{$form-inactive-br-color};
			}

			span {
				color: $form-inactive-fg-color;
			}
		}


		// HIDDEN LABEL
		&.hidden-label .label {
			display: none;
		}
	}


	// RADIO
	fieldset.radio-buttons label span {

		&:before {
			width: Spacing(XS);
			height: Spacing(XS);
			margin-left: Spacing(2XS);
			margin-right: Spacing(XS);
			background-color: var(--radio-checked-color, var(--bg-0-color));
			border: fluid-value(3.5, 4) solid var(--bg-0-color);
			border-radius: 50%;
		}
	}


	// CHECKBOX
	fieldset.checkboxes {
		row-gap: Spacing(XS);

		.label {
			margin-bottom: Spacing(XS);
		}

		label span {
			position: relative;
			@include get-icon(after, $common-icons, check, $form-default-fg-color, Spacing(XS));

			&:before {
				width: fluid-value(28, 35);
				height: fluid-value(28, 35);
				margin-right: Spacing(S);
			}

			&:after {
				position: absolute;
				left: Spacing(2XS);
				opacity: var(--checkbox-opacity, 0);
			}
		}
	}



	//* ANCHOR TEXT LABELS *//

	.label {
		display: inline-block;
		@include typography(ui-normal);
		font-family: $font-family-serif;
		color: var(--tx-secondary-color);
	}



	//* ANCHOR HELP TEXT *//

	.help-text {
		margin: 0px;
		margin-top: Spacing(3XS);
		@include typography(body-small, true);
		color: var(--tx-secondary-color);
	}



	//* ANCHOR ACTIONS *//

	.actions {
		display: flex;
	}



	//* ANCHOR MULTI RANGE SLIDER *//

	.field-type-slider {
		padding-top: 1px; // so that wrapper has slider's height without adding overflow


		// FOUNDATION SLIDER
		.slider {
			margin-top: Spacing(L);
			margin-bottom: Spacing(XS);
	
	
			// RAIL BETWEEN HANDLES
			.slider-fill {
				bottom: 0px;
				margin: auto; 
				height: Spacing(XS);
			}
	
	
			// HANDLES
			.slider-handle {
				display: flex;
				justify-content: center;
				background-color: var(--slider-bg-color, var(--link-default-fg-color));
				border: fluid-value(2, 3) solid var(--slider-br-color, var(--bg-0-color));
				outline: 0px;
	
				// HOVER
				@include hover {
					--slider-bg-color: var(--link-hover-bg-color);
				}
	
				// FOCUS
				&:focus-visible {
					--slider-bg-color: var(--link-focus-fg-color);
					--slider-br-color: var(--link-focus-bg-color);
				}
	
	
				// INPUT
				.slider-label {
					position: absolute;
					bottom: 100%;
				}

				input {
					padding-bottom: Spacing(3XS);
					border: 0px;
					text-align: center;
					background-color: transparent;
					color: var(--tx-primary-color);
					@include typography(ui-normal);
					font-family: $font-family-sans-serif;
					font-weight: $fw-regular;
					outline: none;
				}
			}
		}


		// RANGE
		.range {
			padding-top: Spacing(3XS);
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include typography(ui-small);
			font-family: $font-family-sans-serif;
			font-weight: $fw-regular;
			color: var(--tx-primary-color);
		}


		// INLINE LABEL
		&.inline-label {
			.slider-wrapper {
				flex-grow: 1;
			}
		}
	}
}


//* ANCHOR MULTY SELECTS *//
	
.form .select2-container--default  {

	.select2-selection--multiple {
		padding: 0px;
		border-radius: 0px;


		// ICON
		position: relative;
		padding-right: Spacing(L);
		@include get-icon(after, $common-icons, arrow-down, var(--arrow-color, $action-primary-default-dark), Spacing(S), 1);

		&:after {
			position: absolute;
			right: Spacing(S);
			top: Spacing(XS);
			transition: $global-transition;
		}


		// CHOICE
		.select2-selection__choice {
			background-color: transparent;
			border: 0px;
			@include typography(ui-normal);
			font-family: $font-family-sans-serif;
			font-weight: $fw-regular;
			padding: Spacing(XS) Spacing(S);
			display: inline-flex;
			align-items: center;
		}
		

		// REMOVE BUTTON
		.select2-selection__choice__remove {
			position: static;
			border: 0px;
			padding-left: 0px;
			padding-right: Spacing(2XS);
			background-color: transparent;
			@include get-icon(before, $common-icons, close, var(--remove-btn-color, $action-primary-default-dark), Spacing(S), 1, true);

			&:before {
				transition: $global-transition;
			}

			@include hover {
				--remove-btn-color: #{$action-primary-hover-dark};
			}

			&:focus,
			&:focus-visible {
				--remove-btn-color: #{$action-primary-focus-dark};
			}

			span {
				display: none;
			}
		}

		.select2-selection__choice__display {
			padding: 0px;
		}
	}

	textarea.select2-search__field {
		padding: Spacing(XS) Spacing(S);
		margin: 0px;
		background-color: transparent;
		min-width: 100%;
	}

	&.select2-container--open .select2-selection--multiple {
		border: 0px;
	}


	// FOCUS
	&.select2-container--focus {
		--form-element-br-color: #{$form-focus-br-color};
		--arrow-color: #{$form-focus-link-color};

		.select2-selection--multiple:after {
			transform: rotate(180deg);
		}
	}


	// DISABLED
	&.select2-container--disabled {
		--form-element-br-color: #{$form-inactive-br-color};
		--arrow-color: #{$form-inactive-link-color};
	}
}


// DROPDOWN
.select2-dropdown {
	position: relative;
	box-shadow: inset 0 0 0 $form-element-br-width $form-default-br-color;
	border: 0px;
	outline: 0px;
	border-radius: 0px;
	transition: $global-transition;
	background-color: $form-default-bg-color;
	padding: $form-element-br-width;


	// HIDE TOP / BOTTOM BORDER OF DROPDOWN
	&:before {
		content: " ";
		position: absolute;
		left: $form-element-br-width;
		width: calc(100% - #{$form-element-br-width} * 2);
		height: $form-element-br-width;
		background-color: $form-default-bg-color;
	}

	&.select2-dropdown--above {
		&:before {
			bottom: 0px;
		}

		.select2-results__options {
			margin-bottom: calc(-1 * #{$form-element-br-width});
		}
	}

	&.select2-dropdown--below {
		&:before {
			top: 0px;
		}

		.select2-results__options {
			margin-top: calc(-1 * #{$form-element-br-width});
		}
	}


	// OPTIONS
	.select2-results__option {
		position: relative;
		display: flex;
		align-items: center;
		padding: Spacing(XS) Spacing(S);
		@include typography(ui-normal);
		font-weight: $fw-regular;
		font-family: $font-family-sans-serif;
		color: $action-primary-default-dark;
		transition: $global-transition;


		// Focus
		&:focus,
		&:focus-visible,
		&.select2-results__option--highlighted.select2-results__option--selectable {
			color: $action-primary-focus-light;
			background-color: $action-primary-focus-dark;
		}


		// Highlighted
		&.select2-results__option--highlighted.select2-results__option--selectable {
			@include hover {
				color: $action-primary-hover-light;
				background-color: $action-primary-hover-dark;
			}
		}


		// Selected
		&.select2-results__option--selected {
			color: $action-primary-default-light;
			background-color: $action-primary-default-dark;

			@include get-icon(before, $common-icons, check, inherit, Spacing(S), 1);

			&:before {
				margin-right: Spacing(2XS);
			}
		}


		// Hover
		@include hover {
			color: $action-primary-hover-light;
			background-color: $action-primary-hover-dark;
		}
	}
}
// TAGS

.tag {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 4px;
	background-color: var(--active-bg-color);
	
	p {
		padding: Spacing(XS) Spacing(S);
		margin: 0px;
		@include typography(ui-normal);
		font-family: $font-family-sans-serif;
		font-weight: $fw-regular;
		color: var(--active-fg-color);
	}


	//* ANCHOR NUMBER *//

	span {
		padding: Spacing(3XS);
		@include typography(ui-xsmall);
		font-family: $font-family-sans-serif;
		color: Primary1(900);
		background-color: Primary1(400);
		border-radius: 4px;
		margin-right: Spacing(2XS);
	}



	//* ANCHOR SMALL *//

	&.small {
		p {
			padding: Spacing(2XS) Spacing(XS);
			@include typography(ui-small);
			font-family: $font-family-sans-serif;
			font-weight: $fw-regular;
		}

		span {
			margin-right: Spacing(3XS);
		}
	}
}



//* ANCHOR TAG LIST *//

.tags-list {
	display: flex;
	flex-direction: column;
	gap: Spacing(3XS);


	// FULL WIDTH
	&.full-width {
		.tag {
			width: 100%;
		}
	}


	// HORIZONTAL
	&.horizontal {
		flex-direction: row;
		flex-wrap: wrap;
	}
}
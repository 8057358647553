// MAIN LAYOUT

.page {
	overflow-x: hidden;
	padding-bottom: $header-height;

	@include breakpoint(large) {
		padding-bottom: 0px;
	}
}

main {
	max-width: $app-max-width;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: $main-content-spacing;
	
	@include breakpoint(large) {
		display: grid;
		column-gap: $main-content-spacing;
		grid-template-columns: 25.08fr 74.92fr;
	}



	//* ANCHOR FULL WIDTH ITEMS *//

	.site-header,
	.main-content {
		grid-column: 1 / span 2;
		padding-left: $page-spacing;
		padding-right: $page-spacing;

		& > * {
			width: 100%;
			max-width: $column-max-width;
			margin-left: auto;
			margin-right: auto;
		}
	}

	// SITE HEADER
	.site-header {
		margin-bottom: $main-content-spacing;
	}


	// PROMO FOOTER
	.promo-footer {
		grid-column: 1 / span 2;
		padding-left: $page-spacing;
		padding-right: $page-spacing;
		padding-top: Spacing(2XL);
		margin-top: Spacing(2XL);

		@include breakpoint(large) {
			margin-bottom: calc(-1 * #{$main-content-spacing});
			padding-bottom: $main-content-spacing;
		}

		// Background
		@include full-screen-background(var(--bg-0-color));

		// Border
		@include full-screen-background(var(--br-secondary-color), after);

		&:after {
			height: $br-width;
		}
	}



	//* ANCHOR WITH LEFT SIDEBAR *//

	.sidebar-left {
		display: none;
		padding-left: $page-spacing;
		grid-column: 1;
		border-right: 1px solid var(--br-secondary-color); 

		@include breakpoint(large) {
			display: block;

			& + .main-content {
				grid-column: 2;
				padding-left: 0;
			}
		}
	}



	//* ANCHOR MAIN CONTENT LAYOUT *//

	.main-content > div {
		width: 100%;
		max-width: $column-max-width;
		margin-left: auto;
		margin-right: auto;

		@include breakpoint(large-a) {
			display: grid;
			grid-template-columns: 74.89fr 35.11fr;
			gap: Spacing(XL);

			.content:first-child:last-child {
				grid-column: 1 / span 2;
			}
		}

		.table-of-contents {
			display: none;

			@include breakpoint(large-a) {
				display: block;
			}
		}
	}
}
// DICTIONARY INDEX

.dictionary-index {


	//* ANCHOR LAYOUT *//

	ul {
		display: grid;
		grid-template-columns: repeat(6, Spacing(L));
		grid-template-rows: repeat(5, Spacing(L));
		gap: Spacing(3XS); 
	}



	//* ANCHOR LINKS *//

	a:not(.button) {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		@include typography(ui-small);
		font-family: $font-family-sans-serif;
		font-weight: $fw-regular;
		text-transform: uppercase;
		color: var(--link-default-fg-color);
		background-color: var(--bg-100-color);

		&.active {
			color: var(--active-fg-color);
			background-color: var(--active-bg-color);
		}

		@include hover {
			color: var(--link-hover-fg-color);
			background-color: var(--link-hover-bg-color);
		}

		&:focus-visible {
			color: var(--link-focus-fg-color);
			background-color: var(--link-focus-bg-color);
		}
	}

	// BUTTON
	li.all {
		grid-column: span 4;

		a {
			width: 100%;
			height: 100%;
		}
	}
}
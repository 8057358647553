
//* ANCHOR ICON MAPS *//

// COMMON ICONS
$common-icons: (
	arrow-double-left: 			'\e901',
	arrow-double-right: 		'\e902',
	arrow-up: 					'\e90b',
	arrow-down: 				'\e900',
	arrow-left: 				'\e911',
	arrow-right: 				'\e90a',
	arrow-bend: 				'\e90c',
	arrow-circle: 				'\e90f',
	arrow-square: 				'\e913',
	check: 						'\e905',
	close: 						'\e907',
	warning: 					'\e90e',
	info: 						'\e90d',
	error: 						'\e909',
	search: 					'\e912',
	share: 						'\e910',
	download: 					'\e908',
	filters: 					'\e914',
	asterisk: 					'\e903',
	lightbulb: 					'\e904',
	copy: 						'\e906',
);


// LAYOUT ICONS
$layout-icons: (
	arrow: 						'\e915',
);


// FORM MESSAGE ICONS
$message-icons-colors: (
	error: (
		icon:			map-get($common-icons, error),
		bg:				Error(600),
		icon-color:		Error(300)
	),
	success: (
		icon:			map-get($common-icons, check),
		bg:				Success(600),
		icon-color:		Success(300)
	),
	warning: (
		icon:			map-get($common-icons, warning),
		bg:				Warning(600),
		icon-color:		Warning(300)
	),
	required: (
		icon:			map-get($common-icons, asterisk),
		bg:				Warning(600),
		icon-color:		Warning(300)
	),
	tip: (
		icon:			map-get($common-icons, lightbulb),
		bg:				Primary3(600),
		icon-color:		Primary3(100)
	),
	citation: (
		icon:			map-get($common-icons, arrow-bend),
		bg:				Primary3(700),
		icon-color:		Primary3(300)
	),
);



//* ANCHOR ICON MIXIN *//

// USE: Add icon on selector's pseudo element.

// INSTRUCTIONS: 	
//	Extend on the parent of the pseudo-element that you want to add the icon.
//	$position - the pseudo-element, can be before, after or both. 
// 	$map - the map that includes the selected icon
//	$icon - the key of the icon in the map
// 	$color - the color of the icon
//	$font-size - the font-size of the icon
// 	$line-height - the line-height of the icon
// 	$only-icon - add true if you wish to remove the text of the parent element. Default is false

//EXAMPLES: 
//	@include get-icon(before, $common-icons, search, white, 1rem, 1.3, true);
//	@include get-icon(both, $common-icons, search, primary(0), rem-calc(10));


// MIXIN
@mixin get-icon($position: before, $map: $common-icons, $icon: default, $color: black, $font-size: 1rem, $line-height: 1, $only-icon: false) {
	
	$content: map-get($map, $icon);
	
	@if $position == both {
		$position: 'before, &::after';
	}

	@if $only-icon == true {
		font-size: 0px;
	}

	&::#{$position} {
		content: $content;
		color: $color;
		font-size: $font-size;
		line-height: $line-height;

		// Required icon styles
		font-family: 'eurofound-template-icons';
		speak: never;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;

		// Better Font Rendering
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}
// MIXINS


//* ANCHOR MEDIA HOVER *//

// USE:			Wraps its content with the appropriate hover interaction media feature. This prevents hover effects to appear when not needed, for example in touch screen devices.
// AUTHOR:		EWORX
// EXAMPLE: 	@include hover { color: white; }

@mixin hover {
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			@content;
		}
	}
}



//* ANCHOR SCROLLBARS *//

// USE: 	Mixin to customize scrollbars
// AUTHOR:	Hugo Giraudel, improvements by EWORX

// INSTRUCTIONS:
// $size - Horizontal scrollbar's height and vertical scrollbar's width
// $foreground-color - Scrollbar's color
// $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
// $ff-size - thin - Since size doesn't work in Firefox and scrollbar-width is only supported in FF. Values: thin, auto, none.

// EXAMPLE:   @include scrollbars($scrollbar-width, $scrollbar-thumb-color, $scrollbar-track-color);

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 30%), $ff-size: thin) {

	// For Google Chrome Safari and Edge
	&::-webkit-scrollbar {
		width:  $size;
		height: $size;
	}

	&::-webkit-scrollbar-thumb {
		background: $foreground-color;
	}
	
	&::-webkit-scrollbar-track {
		background: $background-color;
	}

	// For Firefox
	& {
		scrollbar-width: $ff-size;
		scrollbar-color: $foreground-color $background-color;
	}
}



//* ANCHOR BACKGROUND COLOR ANIMATION *//

// USE: Animation from one color to another from all directions
// AUTHOR: EWORX

// INSTRUCTIONS: 
//		For $direction use ttb, btt, ltr and rtl
//		For $state use hover, focus or both

// EXAMPLE: @include bg-color-animation(transparent, $primary-0, btt, hover);

@mixin bg-color-animation(
	$initial-color,
	$final-color,
	$direction,
	$text-color: null,
	$state: both,
	$transition: $global-transition
){

	$final-bg-position: null;

	// PARENT ELEMENT
	transition: $transition;

	// Top to Bottom
	@if $direction == ttb {
		background-image: linear-gradient($initial-color, $initial-color 50%, $final-color 50%, $final-color);
		background-size: 100% 200%;

		$final-bg-position: 0 -100%;
	}

	// Bottom to Top
	@else if $direction == btt {
		background-image: linear-gradient($initial-color, $initial-color 50%, $final-color 50%, $final-color);
		background-size: 100% 200%;

		$final-bg-position: 0 100%;
	}

	// Left to Right
	@else if $direction == ltr {
		background-image: linear-gradient(90deg, $final-color, $final-color 50%, $initial-color 50%, $initial-color);
		background-size: 200% 100%;
		background-position: 100% 0;

		$final-bg-position: 0 0;
	}

	// Right to Left
	@else if $direction == rtl {
		background-image: linear-gradient(90deg, $initial-color, $initial-color 50%, $final-color 50%, $final-color);
		background-size: 200% 100%;

		$final-bg-position: 100% 0;
	}

	// Error
	@else {
		@error 'Direction should be one of the following: ttb, btt, ltr and rtl';
	}


	// STATE
	// Both hover and focus
	@if $state == both {
		@include hover {
			background-position: $final-bg-position;
			color: $text-color;
		}

		&.focus-visible {
			background-position: $final-bg-position;
			color: $text-color;
		}

	}
	
	// Hover
	@else if $state == hover {
		@include hover {
			background-position: $final-bg-position;
			color: $text-color;
		}
	}
	
	// Focus
	@else if $state == focus {
		&:focus-visible {
			background-position: $final-bg-position;
			color: $text-color;
		}
	}
	
	// Error
	@else {
		@error '$state should be one of the following, both, hover, focus';
	}

	@if $state == focus or $state == both {
		&:focus-visible {
			outline: none;
		}
	}
}



//* ANCHOR FULL SCREEN BACKGROUND *//

// USE: expands background-color further than parents width in order to fill the entire screen.
// AUTHOR: EWORX

// EXAMPLE:  @include full-screen-background(grey);

@mixin full-screen-background($background-color, $position: before, $z-index: -1) {
	position: relative;

	&:#{$position} {
		content: " ";
		position: absolute;
		top: 0px;
		right: -100vw;
		bottom: auto;
		left: -100vw;
		margin: auto;
		height: 100%;
		width: 200vw;
		background: $background-color;
		z-index: $z-index;
	}
}
// CITATION PANEL

.citation {


	//* ANCHOR TITLE *//

	.title {
		@include typography(H3);
		margin-top: 0px;
	}



	//* ANCHOR HEADING *//

	.heading {
		@include typography(H4);
		margin-top: 0px;
	}



	//* ANCHOR BUTTON GROUP *//

	.button-group {
		margin-top: Spacing(L);
		gap: Spacing(S);
	}
}
// TEASER

.teaser {
	display: flex;
	flex-direction: column;



	//* ANCHOR CONTENT WRAPPERS *//

	.content-wrapper {
		position: relative;
		border-top: $br-width solid var(--br-primary-color);
		background-color: var(--bg-0-color);
	}

	div.content {
		padding: var(--teaser-padding, Spacing(M) Spacing(L) Spacing(L));
		display: flex;
		flex-direction: column;
	}



	//* ANCHOR LINKS *//

	li > a {
		border-bottom: 0px;
	}



	//* ANCHOR META *//

	.meta {
		display: flex;
		flex-wrap: wrap;
		@include typography(body-small, true);

		li {
			display: flex;
			align-items: center;
			
			&:after {
				content: " ";
				width: 5px;
				height: 5px;
				margin: 0 var(--meta-bullet-spacing, Spacing(3XS));
				border-radius: 50%;
				background-color: var(--tx-tertiary-color);
			} 

			&:last-child:after {
				content: none;
			}
		}

		.date {
			font-weight: $fw-bold;
			color: var(--tx-primary-color);
		}
	}


	// TOP META
	.meta.top {
		order: -1;
		text-transform: uppercase;
		margin-bottom: Spacing(M);
	}



	//* ANCHOR TITLE *//

	.title {
		margin-top: 0px;
		margin-bottom: 0px;
		@include typography(H4, true);

		& > a {
			border-bottom: 0px;
		}
	}



	//* ANCHOR DESCRIPTION *//

	p.description {
		margin-bottom: 0px;
		margin-top: Spacing(XS);
	}



	//* ANCHOR META GROUP *//

	.meta-group {
		margin-top: Spacing(M);
		padding-top: Spacing(S);
		@include typography(body-small, true);

		& + .meta-group {
			margin-top: Sp acing(3XS);
			padding-top: 0px;
		}

		label {
			@include typography(body-xsmall, true);
			color: var(--tx-tertiary-color);
			text-transform: uppercase;
		}

		--meta-bullet-spacing: #{Spacing(2XS)};
	}



	//* ANCHOR ACTIONS *//

	.actions {
		margin-top: Spacing(M);

		li + li {
			margin-top: var(--actions-spacing, Spacing(XS));
		}

		a {
			width: 100%;
		}
	}



	//* ANCHOR FEATURED *//

	&.featured .content-wrapper {
		padding-left: var(--featured-height, Spacing(L));

		.content {
			border-left: 1px solid var(--br-secondary-color);
		}
	}


	// INDICATOR
	.featured {
		position: absolute;
		top: 0px;
		right: 100%;
		padding: var(--featured-padding, fluid-value(6, 7.5) Spacing(M));
		transform: rotate(-90deg);
		transform-origin: top right;
		@include typography(body-small, true);
		font-weight: $fw-bold;
		color: var(--headings-secondary-color);
		text-transform: uppercase;
	}



	//* ANCHOR WITH IMAGE *//

	.image-wrapper {
		order: -1;

		img {
			width: 100%;
		}
	}


	// SIDE IMAGE
	@include breakpoint(medium) {
		&.side-image {
			display: grid;
			grid-template-columns: var(--size-image-width, 250px) 1fr;

			.content-wrapper {
				border-top: 0px;
				border-left: $br-width solid var(--br-primary-color);
			}
		}
	}


	// BACKGROUND IMAGE
	&.background-image {
		height: 100%;
		position: relative;
		
		.content-wrapper {
			flex-grow: 1;
			border-top: 0px;
			background-color: var(--bg-0-trans-color);
		}

		.content {
			height: 100%;
			justify-content: flex-end;
		}

		.image-wrapper {
			position: absolute;
			top: 0px;
			left: 0px;
			height: 100%;
			width: 100%;
			z-index: -1;

			img {
				height: 100%;
				object-fit: cover;
			}
		}
	}



	//* ANCHOR SMALL *//

	&.small {

		// TITLE
		.title {
			@include typography(H6, true);
		}

		// DESCRIPTION
		.description {
			@include typography(body-small, true);
		}

		// XSMALL TEXT
		.featured,
		.meta.top {
			@include typography(body-xsmall, true);
		}

		.featured {
			font-weight: $fw-bold;
		}

		// SPACINGS
		--teaser-padding: #{Spacing(S) Spacing(M) Spacing(M)};
		--featured-height: #{Spacing(M)};
		--featured-padding: #{fluid-value(2, 5) Spacing(S)};
		--actions-spacing: #{Spacing(2XS)};

		// ACTIONS
		.actions .button {
			@extend %small-button;
		}

		// SIDE IMAGE
		--size-image-width: 180px;
	}
}



//* ANCHOR TEASER LIST *//

.teaser-list > li + li {
	margin-top: Spacing(3XS);
}
// PAGE HEADER

.page-header {
	position: relative;
	background-color: var(--bg-0-color);


	//* ANCHOR TITLE *//

	h1 {
		margin: 0px;
	}



	//* ANCHOR SUBTITLE *//

	p.subtitle {
		margin: 0px;
		@include typography(D-H3, true);
		margin-top: Spacing(L);
		color: var(--tx-tertiary-color);
	}



	//* ANCHOR CONTENT *//

	p.content {
		margin: 0px;
		margin-top: Spacing(L);
		color: var(--tx-primary-color);
	}



	//* ANCHOR UPDATED *//

	.tag {
		position: absolute;
		top: Spacing(XS);
		right: Spacing(XS);
	}
}
// COOKIES

.cookies {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	background-color: var(--bg-0-color);
	z-index: 1004;
	
	& > div {
		display: flex;
		flex-direction: column;
		column-gap: Spacing(2XL);
		row-gap: Spacing(L);
		padding: $page-spacing;
		max-width: $app-max-width;
		margin: 0 auto;
		
		@include breakpoint(medium-a) {
			flex-direction: row;
			justify-content: space-between;
		}
	}


	//* ANCHOR ACTIONS *//

	.content {
		p:first-child {
			margin-top: 0px;
		}

		p:last-child {
			margin-bottom: 0px;
		}
	}


	//* ANCHOR ACTIONS *//

	.actions {
		display: flex;
		flex-direction: column;
		gap: Spacing(2XS);

		@include breakpoint(small-a) {
			flex-direction: row;
		}

		@include breakpoint(medium-a) {
			flex-direction: column;
		}

		.button {
			white-space: nowrap;
		}
	}
}
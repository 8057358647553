// HERO

.hero {
	text-align: center;
	padding-top: fluid-value(48, 120);
	padding-bottom: fluid-value(48, 120);


	//* ANCHOR TITLE *//

	.title {
		@include typography(D0);
		margin-top: 0px;
		margin-bottom: Spacing(L);
	}



	//* ANCHOR IMAGE *//

	.image-wrapper {
		position: absolute;
		top: 0px;
		left: -100vw;
		right: -100vw;
		margin: auto;
		width: 100vw;
		max-width: $app-max-width;
		height: 100%;
		z-index: -2;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}



	//* ANCHOR SECTION *//

	&.section {
		@include full-screen-background(var(--bg-0-trans-color));
	}



	//* ANCHOR PANEL *//

	&.panel {
		position: relative;
		@include full-screen-background(var(--bg-0-trans-color));

		@include breakpoint(large) {
			background-color: var(--bg-0-trans-color);

			&:before {
				content: none;
			}

			.image-wrapper {
				width: 100%;
				left: 0px;
				right: auto;
			}
		}
	}
}
// MESSAGES


//* ANCHOR FORM MESSAGES *//

.form-message {
	flex-basis: 100%;
	display: flex;
	align-items: center;
	padding: 0 Spacing(3XS);
	@include typography(body-small, true);
	color: white;
	background-color: var(--message-bg-color);
	border-radius: 4px;
	@include get-icon(before, $common-icons, icon, var(--message-icon-color), Spacing(XS));

	&:before {
		margin-right: Spacing(3XS);
	}
}



//* ANCHOR CALLOUT MESSAGES *//

.message.callout {
	display: flex;
	padding: 0px;
	background-color: var(--message-bg-color);
	@include get-icon(before, $common-icons, icon, var(--message-icon-color), Spacing(XL));
	
	& > div {
		padding: Spacing(M);
		margin-right: auto;
	}

	.button.icon-close {
		flex-shrink: 0;
	}
	

	// ICON
	&:before {
		flex-shrink: 0;
		padding: Spacing(M);
		padding-right: 0px;
	}

	&.no-icon:before {
		content: none !important;
	}


	// TEXT
	p {
		color: white;

		&.title {
			@include typography(H4, true);
			margin: 0px;
		}
	
		& + p {
			font-weight: $fw-regular;
			margin-top: Spacing(XS);
			margin-bottom: 0px;
		}
	}


	// LINKS
	a {
		color: white;
		border-bottom: 1px solid white;

		@include hover {
			background-color: white;
			color: var(--link-hover-bg-color);
		}

		&:focus-visible {
			background-color: white;
			color: var(--link-focus-bg-color);
		}
	}
}




//* ANCHOR COLORS AND ICONS *//

.form-message,
.message.callout {
	@each $class, $info in $message-icons-colors {

		$icon: map-get($info, icon);
		$bg-color: map-get($info, bg);
		$icon-color: map-get($info, icon-color);

		&.#{$class} {
			--message-bg-color: #{$bg-color};
			--message-icon-color: #{$icon-color};

			&:before {
				content: $icon;
			}
		}
	}
}
// ACCORDION MENU

ul.accordion-menu {


	//* ANCHOR FIRST LEVEL *//

	& > li {
		border-top: 1px solid var(--br-secondary-color);

		& > a {
			@include typography(H6, true);
		}
	}



	//* ANCHOR INNER LEVELS *//

	& > li > ul > li > a {
		@include typography(body-small, true);
	}



	//* ANCHOR ARROWS *//

	.is-accordion-submenu-parent > a {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include get-icon(after, $common-icons, arrow-down, inherit, rem-calc(15));

		&:after {
			margin-left: Spacing(XS);
		}
	}



	//* ANCHOR ACTIVE *//

	li[aria-expanded="true"] > a {
		color: var(--active-fg-color);
		background-color: var(--active-bg-color);

		&:after {
			transform: rotate(180deg);
		}


		// FOCUS
		&:focus-visible {
			color: var(--link-focus-fg-color);
			background-color: var(--link-focus-bg-color);
		}


		// HOVER
		@include hover {
			color: var(--link-hover-fg-color);
			background-color: var(--link-hover-bg-color);
		}
	}
}
// TABLE OF CONTENTS

.table-of-contents {
	overflow: hidden;


	//* ANCHOR TITLE *//

	h2.title {
		margin: 0px;
		margin-bottom: Spacing(XS);
		@include typography(ui-xsmall);
		color: var(--tx-primary-color);
		font-family: $font-family-sans-serif;
		font-weight: $fw-regular;
	}



	//* ANCHOR MENU *//

	ul.menu li {
		border-top: 1px solid var(--br-secondary-color);

		a {
			@include typography(body-small, true);
			font-weight: $fw-regular;


			// ACTIVE
			&.is-active {
				color: var(--active-fg-color);
				background-color: var(--active-bg-color);
			}
		}
	}



	//* ANCHOR DECORATIVE ICON *//

	&:after {
		content: " ";
		position: fixed;
		bottom: 0px;
		right: $page-spacing;
		width: 20%;
		height: 100%;
		background-image: url(../img/toc-logo.svg);
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center bottom;
		z-index: -1;
		pointer-events: none;

		@include breakpoint(large-b) {
			width: 20.5%;
		}

		@include breakpoint(xlarge) {
			width: 21%;
		}
	}
}
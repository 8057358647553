
// TYPOGRAPHY

//* ANCHOR MAP *//
// This map includes all the design system's typographic styles.
// You can add more css rules and they will be included automatically, no need to update the mixin.
// IMPORTANT NOTE: Do not use units for min and max values.

$typography: (
	D1: (
		font-family: $font-family-serif,
		font-weight: $fw-regular,
		font-size: (
			min: 59,
			max: 95
		),
		line-height: (
			min: 74,
			max: 119
		),
		letter-spacing: -0.02em,
		margin-top: (
			min: 74,
			max: 119
		),
		margin-bottom: (
			min: 74,
			max: 119
		),
	),
	D0: (
		font-family: $font-family-serif,
		font-weight: $fw-regular,
		font-size: (
			min: 49,
			max: 76
		),
		line-height: (
			min: 61,
			max: 95
		),
		letter-spacing: -0.02em,
		margin-top: (
			min: 61,
			max: 95
		),
		margin-bottom: (
			min: 61,
			max: 95
		),
	),
	D-H2: (
		font-family: $font-family-serif,
		font-weight: $fw-regular,
		font-size: (
			min: 34,
			max: 49
		),
		line-height: (
			min: 44,
			max: 64
		),
		letter-spacing: -0.02em,
		margin-top: (
			min: 44,
			max: 64
		),
		margin-bottom: (
			min: 44,
			max: 64
		),
	),
	D-H3: (
		font-family: $font-family-serif,
		font-weight: $fw-regular,
		font-size: (
			min: 28,
			max: 39
		),
		line-height: (
			min: 38,
			max: 53
		),
		letter-spacing: -0.02em,
		margin-top: (
			min: 38,
			max: 53
		),	
		margin-bottom: (
			min: 38,
			max: 53
		),	
	),
	H1: (
		font-family: $font-family-serif,
		font-weight: $fw-bold,
		font-size: (
			min: 41,
			max: 61
		),
		line-height: (
			min: 53,
			max: 73
		),
		letter-spacing: -0.02em,
		margin-top: (
			min: 53,
			max: 73
		),	
		margin-bottom: (
			min: 53,
			max: 73
		),	
	),
	H2: (
		font-family: $font-family-serif,
		font-weight: $fw-bold,
		font-size: (
			min: 34,
			max: 49
		),
		line-height: (
			min: 44,
			max: 59
		),
		letter-spacing: -0.02em,
		margin-top: (
			min: 44,
			max: 59
		),	
		margin-bottom: (
			min: 44,
			max: 59
		),	
	),
	H3: (
		font-family: $font-family-serif,
		font-weight: $fw-bold,
		font-size: (
			min: 28,
			max: 39
		),
		line-height: (
			min: 38,
			max: 47
		),
		letter-spacing: 0em, // so that is overrides other letter-spacing rules when used in a class.
		margin-top: (
			min: 38,
			max: 47
		),
		margin-bottom: (
			min: 38,
			max: 47
		),
	),
	H4: (
		font-family: $font-family-serif,
		font-weight: $fw-bold,
		font-size: (
			min: 23,
			max: 31
		),
		line-height: (
			min: 28,
			max: 37
		),
		letter-spacing: 0em, // so that is overrides other letter-spacing rules when used in a class.
		margin-top: (
			min: 28,
			max: 37
		),
		margin-bottom: (
			min: 28,
			max: 37
		),
	),
	H5: (
		font-family: $font-family-serif,
		font-weight: $fw-bold,
		font-size: (
			min: 19,
			max: 25
		),
		line-height: (
			min: 23,
			max: 30
		),
		letter-spacing: 0em, // so that is overrides other letter-spacing rules when used in a class.
		margin-top: (
			min: 23,
			max: 30
		),
		margin-bottom: (
			min: 23,
			max: 30
		),
	),
	H6: (
		font-family: $font-family-serif,
		font-weight: $fw-bold,
		font-size: (
			min: 16,
			max: 20
		),
		line-height: (
			min: 19,
			max: 24
		),
		letter-spacing: 0em, // so that is overrides other letter-spacing rules when used in a class.
		margin-top: (
			min: 19,
			max: 24
		),
		margin-bottom: (
			min: 19,
			max: 24
		),
	),
	body-normal: (
		font-family: $font-family-sans-serif,
		font-weight: $fw-light,
		font-size: (
			min: 16,
			max: 20
		),
		line-height: (
			min: 25,
			max: 31
		),	
		letter-spacing: 0em, // so that is overrides other letter-spacing rules when used in a class.
		margin-top: (
			min: 25,
			max: 31
		),		
		margin-bottom: (
			min: 25,
			max: 31
		),		
	),
	body-small: (
		font-family: $font-family-sans-serif,
		font-weight: $fw-regular,
		font-size: (
			min: 13,
			max: 16
		),
		line-height: (
			min: 20,
			max: 25
		),
		letter-spacing: 0em, // so that is overrides other letter-spacing rules when used in a class.
		margin-top: (
			min: 20,
			max: 25
		),
		margin-bottom: (
			min: 20,
			max: 25
		),
	),
	body-xsmall: (
		font-family: $font-family-sans-serif,
		font-weight: $fw-regular,
		font-size: (
			min: 11,
			max: 13
		),
		line-height: (
			min: 17,
			max: 20
		),
		letter-spacing: 0em, // so that is overrides other letter-spacing rules when used in a class.
		margin-top: (
			min: 17,
			max: 20
		),
		margin-bottom: (
			min: 17,
			max: 20
		),
	),
	ui-normal: (
		font-family: $font-family-serif,
		font-weight: $fw-bold,
		font-size: (
			min: 16,
			max: 20
		),
		line-height: (
			min: 16,
			max: 20
		),
	),
	ui-small: (
		font-family: $font-family-serif,
		font-weight: $fw-bold,
		font-size: (
			min: 13,
			max: 16
		),
		line-height: (
			min: 13,
			max: 16
		),
	),
	ui-xsmall: (
		font-family: $font-family-serif,
		font-weight: $fw-bold,
		font-size: (
			min: 11,
			max: 13
		),
		line-height: (
			min: 12,
			max: 14
		),
	),
);



//* ANCHOR TYPOGRAPHY MIXIN *//

// USE:			Exports typographic elements from $typography map
// AUTHOR:		EWORX

// INSTRUCTIONS: 
//	For $element-to-export use one of the keys in $typography map.

// CASES: 
// 1. Export all typographic elements by including the mixin in an @each loop 
// @each $element, $styles in $typography {
// 	#{$element} {
// 		@include typography($element);
// 	}
// }

// 2. Include on a specific element.
// h2.styled-as-h3 {
// 		@include typography(h3, true);
// }

// 3. Create a placeholder for this specific style in order to reduce css size.
// %paragraph-styles {
// 		@include typography(p);
// }

@mixin typography($element-to-export, $remove-margins: false) {

	// Initial map 
	$element-styles: get-value-if-key-exists($typography, $element-to-export);

	// Final map that will be exported
	$styles-to-export: null;


	// REMOVE MARGINS
	@if $remove-margins == true {
		$remove-margin-top: map-remove($element-styles, margin-top);
		$styles-to-export: map-remove($remove-margin-top, margin-bottom);
	}

	@else {
		$styles-to-export: $element-styles
	}


	// EXPORT STYLES
	@each $property, $value in $styles-to-export {

		@if type-of($value) == 'map' {
			$min: map-get($value, min);
			$max: map-get($value, max);

			#{$property}: fluid-value($min, $max);
		}

		@else {
			#{$property}: $value;
		}
	}
}



//* ANCHOR EXTRACT MIN OR MAX VALUE FROM TYPOGRAPHY MAP *//

// USE: 	Extracts the min or max value from the typography map. Used mostly in foundation settings.
// AUTHOR: 	EWORX
// EXAMPLE:
// $typography: (
// 		D1: (
// 			font-size: (
// 				min: 59,
// 				max: 95
// 			)
// 		);
// 	font-size: fluid-value(typography-min-max(D1, font-size, min), typography-min-max(D1, font-size, max));

@function typography-min-max($style-name, $property, $min-max) {
	$inner-map: nested-map-get($typography, $style-name, $property);

	@return map-get($inner-map, $min-max);
}
// MODALS

.reveal {
	padding: Spacing(XL);
	height: 100%;
	display: flex;
	flex-direction: column;



	//* ANCHOR HEADING *//

	.modal-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: $br-width solid var(--br-secondary-color);
		padding-bottom: Spacing(L);
		margin-bottom: Spacing(L);
	}



	//* ANCHOR TITLE *//

	.title {
		margin: 0px;
		@include typography(H3, true);
	}



	//* ANCHOR CONTENT *//

	.content {
		flex-grow: 1;
		max-width: 867px;
		margin: 0 auto;
		overflow-y: auto;
		overflow-x: hidden;
	}



	//* ANCHOR PADDED *//

	&.padded {
		@include breakpoint(medium) {
			width: calc(100% - #{Spacing(2XL)} * 2);
			height: calc(100% - #{Spacing(2XL)} * 2);
			top: Spacing(2XL) !important;
		}
	}
}
// ACCORDION

ul.accordion {


	//* ANCHOR ITEM *//

	li.accordion-item {
		position: relative;

		& + li {
			margin-top: Spacing(L);
		}
	}



	//* ANCHOR TRIGGER *//

	li.accordion-item > a {
		position: static;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: Spacing(XS);
		transition: $global-transition;
		color: var(--tx-primary-color);

		& > div {
			flex-grow: 1;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: Spacing(XS);
		}


		// ICON
		@include get-icon(after, $common-icons, arrow-down, var(--icon-color, var(--link-default-fg-color)), Spacing(M));

		&:after {
			flex-shrink: 0;
			transition: inherit;
		}


		// BORDER
		&:before {
			content: " ";
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			border: fluid-value(2, 3) solid var(--br-color, var(--br-secondary-color));
			pointer-events: none;
			transition: inherit;
		}


		// HOVER
		@include hover {
			color: $accordion-item-color;
			background-color: $accordion-background;
			--br-color: var(--link-hover-bg-color);
			--icon-color: var(--link-hover-bg-color);
		}


		// FOCUS
		&:focus-visible {
			color: $accordion-item-color;
			background-color: $accordion-background;
			--br-color: var(--link-focus-bg-color);
			--icon-color: var(--link-focus-bg-color);
		}
	}



	//* ANCHOR TITLE *//
	
	.title {
		margin: 0;
		flex-basis: 100%;
		@include typography(H4, true);
		color: var(--tx-primary-color);
	}



	//* ANCHOR SUBTITLE *//
	
	.subtitle {
		margin: 0;
		@include typography(ui-normal);
		font-weight: $fw-regular;
		font-family: $font-family-sans-serif;
		color: var(--tx-primary-color);
	}



	//* ANCHOR ACTIVE *//

	li.accordion-item.is-active > a {
		--br-color: var(--active-bg-color);

		&:after {
			transform: rotate(180deg);
		}
	}



	//* ANCHOR CONTENT *//		
		
	.accordion-content {
		border-top: 0px;

		& > *:last-child {
			margin-bottom: 0px;
		}
	}



	//* ANCHOR IN GRID *//
	
	&.grid-x {
		& > li + li {
			margin-top: 0px;
		}

		@include breakpoint(large) {
			align-items: flex-start;
		}
	}
}
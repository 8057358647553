// LISTS


//* ANCHOR PLACEHOLDERS *//

%list {
	list-style-type: none;
	@include typography(body-normal);

	&.small-text {
		@include typography(body-small);
	}

	&.xsmall-text {
		@include typography(body-xsmall);
	}
}

%list-item {
	position: relative;
	padding-left: var(--list-item-padding-left);

	&:before {
		position: absolute;
		display: inline-block;
	}

	// Nested lists
	& > ul.bullet-list,
	& > ol.number-list {
		margin: 0px;
	}
}


//* ANCHOR BULLET LIST *//

ul.bullet-list {
	@extend %list;

	& > li {
		@extend %list-item;
		--list-item-padding-left: #{fluid-value(84, 105)};

		&:before {
			content: " ";
			top: var(--list-decor-top, fluid-value(8, 11));
			left: var(--list-decor-left, Spacing(XL));
			width: var(--list-decor-size, fluid-value(10, 12));
			height: var(--list-decor-size, fluid-value(10, 12));
			border-radius: 50%;
			background-color: var(--list-decor-color);
		}
	}


	// SMALL TEXT
	&.small-text > li {
		--list-item-padding-left: 	#{fluid-value(56, 70)};
		--list-decor-top: 			#{fluid-value(7, 9)};
		--list-decor-left: 			#{Spacing(L)};
		--list-decor-size: 			#{fluid-value(7, 9)};
	}


	// EXTRA SMALL TEXT
	&.xsmall-text > li {
		--list-item-padding-left: 	#{fluid-value(44, 55)};
		--list-decor-top: 			#{fluid-value(5, 6)};
		--list-decor-left: 			#{Spacing(M)};
		--list-decor-size: 			#{fluid-value(6, 8)};
	}
}



//* ANCHOR NUMBER LIST *//

ol.number-list {
	@extend %list;
	counter-reset: counter;

	& > li {
		@extend %list-item;
		--list-item-padding-left: #{fluid-value(72, 90)};

		&:before {
			content: counter(counter);
			counter-increment: counter;
			top: 0;
			right: calc(100% - var(--list-item-padding-left));
			margin-right: var(--list-decor-right, #{Spacing(M)});
			font-weight: $fw-bold;
			text-align: right;
			color: var(--list-decor-color);
		}
	}


	// SMALL TEXT
	&.small-text > li {
		--list-item-padding-left: 	#{fluid-value(48, 60)};
		--list-decor-right: 		#{Spacing(S)};
	}


	// EXTRA SMALL TEXT
	&.xsmall-text > li {
		--list-item-padding-left: 	#{fluid-value(36, 45)};
		--list-decor-right: 		#{Spacing(XS)};
	}
}



//* ANCHOR SMALL LIST *//

div.small-list {

	// HEADING
	& > .heading {
		@include typography(body-normal, true);
		margin: 0px;
		margin-bottom: Spacing(XS);
		font-weight: $fw-bold;
		color: var(--headings-secondary-color);
	}


	// LIST
	& > ul {
		@include typography(ui-normal);
		font-weight: $fw-regular;
		font-family: $font-family-sans-serif;

		& > li {
			padding-left: #{Spacing(S)};

			& + li {
				margin-top:  Spacing(2XS);
			}
		}
	}


	// HORIZONTAL
	&.horizontal {
		@include breakpoint(medium up) {
			display: flex;
			flex-wrap: wrap;
			gap: Spacing(S);

			& > .heading {
				margin-bottom: 0px;
			}

			& > ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: Spacing(2XS);

				& > li + li {
					margin-top: 0px;
				}
			}
		}
	}


	// BULLETS
	&.bullets > ul > li {
		$bullet-size: 5px;
		position: relative;
		padding-left: Spacing(2XS);
		margin-left: $bullet-size;

		&:before {
			content: " ";
			position: absolute;
			top: fluid-value(5.5, 8);
			left: -#{$bullet-size};
			margin: auto;
			width: $bullet-size;
			height: $bullet-size;
			border-radius: 50%;
			background-color: var(--list-decor-color);
		}
	}
}



//* ANCHOR DEFINITION LIST *//

dl {
	border-bottom: 1px solid var(--br-secondary-color);

	@include breakpoint(medium) {
		display: grid;
		grid-template-columns: min-content 1fr;
	}
	
	dt, dd {
		padding: Spacing(XS) 0 Spacing(S) 0;
		@include typography(body-normal, true);
		color: var(--tx-primary-color);
		border-top: 1px solid var(--br-secondary-color);
	}


	// TERM
	dt {
		padding-right: Spacing(M);
		white-space: nowrap;
		font-weight: $fw-bold;
	}
}
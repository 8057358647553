// FILTERS

.filters {


	//* ANCHOR LAYOUT *//

	&,
	form,
	.fields {
		display: flex;
		flex-direction: column;
		gap: $form-element-spacing;

		@include breakpoint(medium) {
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			column-gap: $form-element-spacing-large;
		}
	}


	// CENTER ALIGN
	&.center {
		@include breakpoint(large) {
			justify-content: center;
		}
	}



	//* ANCHOR RESULTS NUMBER *//

	.results-number {
		margin: 0px;
		@include typography(ui-small);
		font-family: $font-family-sans-serif;
		font-weight: $fw-regular;
		color: var(--tx-primary-color);
	}


	
	//* ANCHOR LABELS *//

	form .label {
		@include typography(ui-small);
		color: var(--tx-primary-color);
	}
}
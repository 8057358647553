// FONT FILES


//* ANCHOR MAIN FONT FILES *//
// SOURCE SERIF PRO GOOGLE FONT

@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,300;0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');



//* ANCHOR ICON FONT FILES *//
// Created via icomoon https://icomoon.io/

@font-face {
	font-family: 'eurofound-template-icons';
	src:
		url('../fonts/eurofound-template-icons.woff2') format('woff2'),
		url('../fonts/eurofound-template-icons.ttf') format('truetype'),
		url('../fonts/eurofound-template-icons.woff') format('woff'),
		url('../fonts/eurofound-template-icons.svg') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
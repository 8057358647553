// COMMON ELEMENTS
// Global rules for common / basic html elements.


//* ANCHOR SCROLLBARS *//

html, // FF fix
body {
	@media (hover: hover) and (pointer: fine) {
		@include scrollbars($scrollbar-width, $scrollbar-thumb-color, $scrollbar-track-color);
	}
}



//* ANCHOR DISPLAY HEADINGS *//

.display-1 {
	@include typography(D1);
}

.display-0 {
	@include typography(D0);
}

.display-h2 {
	@include typography(D-H2);
}

.display-h3 {
	@include typography(D-H3);
}



//* ANCHOR HEADINGS *//

// HEADINGS 1 THROUGH 6
@for $i from 1 through 6 {
	h#{$i}, .h#{$i} {
		@include typography(H#{$i});
	}
}



//* ANCHOR BODY TEXT *//

p {
	@include typography(body-normal);
	color: var(--tx-primary-color);
}

.small-text {
	@include typography(body-small);
}

.xsmall-text {
	@include typography(body-xsmall);
}



//* ANCHOR REMOVE MARGIN TOP FROM FIRST CHILD *//

h1, h2, h3, h4, h5, h6, p {
	&:first-child {
		margin-top: 0px;
	}
}



//* ANCHOR DIVIDERS *//

hr {
	&.large {
		--hr-spacing-tb: #{Spacing(L)};
	}

	&.medium {
		--hr-spacing-tb: #{Spacing(M)};
	}

	&.xsmall {
		--hr-spacing-tb: #{Spacing(XS)};
	}
}



//* ANCHOR LINKS *//

a {
	color: var(--link-default-fg-color);
	transition: color $global-transition, background-color $global-transition;


	// FOCUS
	&:focus {
		outline: none;
		color: var(--link-default-fg-color); // overrides foundation's rule
	}

	&:focus-visible {
		outline: none;
		color: var(--link-focus-fg-color);
		background-color: var(--link-focus-bg-color);
	}


	// HOVER
	@include hover {
		color: var(--link-hover-fg-color);
		background-color: var(--link-hover-bg-color);
	}
}



//* ANCHOR EXTERNAL LINKS *//

a.external {
	@include get-icon(before, $common-icons, arrow-square, Primary5(200), rem-calc(15));

	&:before {
		position: relative;
		top: 1px;
		margin-right: Spacing(XS);
	}
}